import React from 'react'
import AppRoutes from './routes'
import "./App.css"
import firstimage from './assets/Oryze-Menu-Maintenance-AA.png'
import secondimage from './assets/Oryze-Menu-Maintenance-BB.png'

const App = () => {
  return (
    <div style={{ width: '100vw', overflowX: 'hidden' }}>
    {/** <AppRoutes /> */}
    <img src={firstimage} style={{
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: '100vw',
        height: '100vh',
        overflowX: 'hidden'
    }}/>
    <img src={secondimage} style={{
        position: 'fixed',
        left: 0,
        bottom: 0,
        right: 0,
        width: '100vw',
        height: '8rem' // Adjusted to match Tailwind's 'h-32' assuming 1rem = 16px
    }}/>
</div>


  )
}

export default App